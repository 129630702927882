import React from "react";
import classNames from "classnames";
import styles from "./svg.module.css";

const addpx = (value: string) => {
  return String(value).includes("px") ? value : `${value}px`;
};
export type SvgIconProps = {
  height?: string;
  width?: string;
  bgColor?: string;
  url?: string;
  [key: string]: any;
  className?: string;
};
const SvgIcon: React.FC<SvgIconProps> = (props) => {
  const {
    height,
    width,
    bgColor = "#fff",
    url = "",
    className,
    ...rest
  } = props;

  let style = {
    backgroundColor: bgColor,
    mask: `url(${url}) no-repeat`,
    WebkitMask: `url(${url}) no-repeat`,
    WebkitMaskPosition: "center",
    WebkitMaskSize: "contain",
    maskPosition: "center",
    maskSize: "contain",
  } as any;
  if (width && height) {
    style.height = addpx(height);
    style.width = addpx(width);
  }

  return (
    <span
      className={classNames(styles.mask, className)}
      style={style}
      {...rest}
    ></span>
  );
};

export default SvgIcon;
