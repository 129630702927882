import { useRouter } from "next/router";
import { isInSNApp } from "../../services/JSBridgeAdapter";
import { useCallback, useEffect } from "react";

// import { getDeviceData, openVideoLink } from "../../services/JSBridgeAdapter";
type P = {
  href: string;
  tag?: string;
  children?: any;
  enableShare?: boolean;
  onClick?: (e: any, ...rest: any) => void;
  [key: string]: any;
};
const SNLink: React.FC<P> = (props) => {
  const {
    href,
    tag = "div",
    onClick,
    enableShare,
    children,
    disabled = false,
    ...rest
  } = props;
  const Tag = tag;
  const router = useRouter();

  const handleClick = useCallback(
    async (e) => {
      if (disabled) {
        return;
      }
      const inApp = isInSNApp();
      const debug =
        process.env.NEXT_PUBLIC_ENV === "development"
          ? href.includes("?")
            ? "&__debug__"
            : "?__debug__"
          : "";
      const alink = `${href}${debug}`;
      const link = `${window.location.origin}${alink}`;
      console.log("SNLink", link, "inApp", inApp);
      if (onClick) {
        onClick(e, href);
      }

      if (inApp) {
        const deepLink = `smartnews://openBridgeLink?url=${encodeURIComponent(
          link
        )}&displayMode=edgeToEdge&enableSwipeBack=false`;
        window.open(deepLink);
      } else {
        router.push(alink);
      }
    },
    [href, disabled, router, onClick]
  );

  useEffect(() => {
    window.addEventListener("load", () => {
      setTimeout(() => {
        router.prefetch(href);
      }, 500);
    });

    return () => {};
  }, [href]);

  return (
    // @ts-ignore
    <Tag onClick={handleClick} {...rest}>
      {children}
    </Tag>
  );
};

export default SNLink;
