import {
  SmartNewsPixel,
  buildSmartNewsUser,
  buildSmartNewsApp,
  EventDataMap,
  Environment,
  SmartNewsUser,
} from "@smartnews/pixel";
import SNClientBridge from "@smartnews/sn-client-bridge";

const SN_PIXEL_API_KEY = "0dbd8948-f374-4c91-a562-6a0c70de452c";
const environment =
  (process.env.NEXT_PUBLIC_ENV as Environment) || "development";

const doNothing = () => {};

const noConsole = {
  debug: doNothing,
  info: doNothing,
  warn: doNothing,
  error: doNothing,
};

export class SnPixel {
  private static instance: SmartNewsPixel<EventDataMap> | undefined;

  private static initialized = false;

  public static get pixel(): SmartNewsPixel<EventDataMap> {
    if (this.instance === undefined) {
      this.instance = new SmartNewsPixel<EventDataMap>(SN_PIXEL_API_KEY, {
        environment,
        // comment below to show sn-pixel logs
        log: noConsole,
      });
    }

    return this.instance;
  }

  public static async init() {
    if (!this.initialized && this.pixel) {
      try {
        const nextRoot = document.getElementById("__next");
        if (!nextRoot) {
          throw new Error("Next root not found.");
        }
        this.pixel.observe(nextRoot);
        this.initialized = true;
      } catch (error) {
        console.error(error);
      }

      try {
        const { result } = await SNClientBridge.postMessage("getAppInfo", {
          callbackTimeout: 8000,
        });

        this.pixel.app = {
          version: "0.1.0",
          environment,
          ...buildSmartNewsApp(result),
        };

        this.pixel.user = {
          ...buildSmartNewsUser(result),
        } as SmartNewsUser;
        console.log("smartnews user", this.pixel.user);
      } catch (error) {
        console.log("fetch bridge info error", error);
      }
    }
  }
}
