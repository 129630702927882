// @ts-nocheck
import SNClientBridge from "@smartnews/sn-client-bridge";
import compareVersions from "compare-versions";
const SHARE_ANDROID_VERSION = "8.29.0";
const SHARE_IOS_VERSION = "8.30.0";

const FULLSCREEN_VERSION = "22.4.10";

const storageKey = "deviceData";
let commonParams = {
  appVersion: null,
  platform: null,
  edition: null,
  osVersion: null,
  sandbox: null,
  deviceToken: null,
  lang: null,
};
export interface CommonParamsI {
  appVersion: string;
  platform: string;
  edition: string;
  osVersion: string;
  sandbox: string;
  deviceToken: string;
  lang: string;
}
export type NavBarInfo = {
  statusBarHeight: number;
  actionBarHeight: number;
  [key: string]: any;
};

const defaultNavBar = {
  statusBarHeight: 0,
  actionBarHeight: 0,
  show: false,
  enableShare: false,
};

export function isInSNApp() {
  try {
    return SNClientBridge.hasBridgeInterface();
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function setDeviceData(): Promise<CommonParamsI> {
  try {
    if (isInSNApp()) {
      const data = await SNClientBridge.postMessage("getAppInfo", {
        type: "system",
      });
      Object.keys(commonParams).forEach((key) => {
        commonParams[key] = data.result[key];
      });
    } else {
      commonParams = {
        platform: "Web",
        osVersion: "",
        sandbox: "",
        deviceToken: "",
        appVersion: "",
      };
    }

    const serialData = JSON.stringify(commonParams);
    const localData: string = localStorage.getItem(storageKey);
    if (localData !== serialData) {
      localStorage.setItem(storageKey, serialData);
    }

    return commonParams;
  } catch (e) {
    // non device version ignore
    return null;
  }
}

export async function getDeviceData(): Promise<CommonParamsI> {
  const localData: string = localStorage.getItem(storageKey);

  if (!localData) {
    const mobileData = await setDeviceData();
    return mobileData;
  }

  const res = {
    ...JSON.parse(localData),
  };

  return res;
}

const shareMsg =
  "[SmartNews What to Watch] check out the trendiest movies & shows you don't want to miss!";
export async function goShare(url?: string) {
  if (!url) {
    url = window.location.href;
  }
  try {
    await SNClientBridge.postMessage("share", {
      url,
      message: shareMsg,
    });
  } catch (e) {
    console.log(e);
    if (navigator.share) {
      navigator
        .share({
          text: "shareMessage",
          message: shareMsg,
          url,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    }
  }
}

export function validVersion(platform, appVersion, baseVersion): boolean {
  if (!isInSNApp() || !appVersion) {
    return false;
  }
  if (["android", "ios"].includes(platform)) {
    return compareVersions(appVersion, baseVersion) >= 0;
  }

  return false;
}
export const supportShare = async () => {
  const data = await getDeviceData();
  return (
    validVersion("android", data.appVersion, SHARE_ANDROID_VERSION) ||
    validVersion("ios", data.appVersion, SHARE_IOS_VERSION)
  );
};

export const goBack = () => {
  try {
    SNClientBridge.postMessage("closeBridgeWindow");
  } catch (error) {
    console.error(error);
  }
};

export const showNavBar = async () => {
  const data = await getDeviceData();
  if (["android", "ios"].includes(data.platform)) {
    return (
      validVersion("android", data.appVersion, FULLSCREEN_VERSION) ||
      validVersion("ios", data.appVersion, FULLSCREEN_VERSION)
    );
  }
  return true;
};

export const getNavBarInfo = async () => {
  try {
    const inApp = isInSNApp();

    // console.log("show", show);
    if (inApp) {
      const contextInfo = await SNClientBridge.postMessage("getContextInfo");
      const enableShare = await supportShare();

      const { result = {} } = contextInfo;
      let appInfo: AppInfo = result && result.browser ? result.browser : result;

      let res = appInfo || defaultNavBar;
      // setTimeout(() => {
      //   console.log("contextInfo1", contextInfo);
      //   console.log("getNavBarInfo", appInfo, inApp);
      // }, 3000);
      return {
        ...res,
        show: res.displayMode === "edgeToEdge",
        inApp,
        enableShare,
        displayMode: res.displayMode,
      };
    }
    return { ...defaultNavBar, show: true, inApp };
  } catch (err) {
    console.log(err);
    return defaultNavBar;
  }
};

export const openPage = (url) => {
  SNClientBridge.postMessage("openBridgeWindow", {
    url,
    enableSwipeBack: false,
    enableShare: false,
    displayMode: "edgeToEdge",
  });
};

export const openVideoLink = (link, inApp) => {
  const deepLink = `${link}&hide_title=${inApp}`;
  //  window.open(deepLink);
  SNClientBridge.postMessage("openWindow", {
    url: deepLink,
    enableSwipeBack: false,
    enableShare: false,
  });
};
