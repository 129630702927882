import { useEffect } from 'react';
import { SnPixel } from '../sn-pixel';

type TrackFn = <N>(
  event: N,
  data: any
) => Promise<void>;

export function usePixel(): [TrackFn] {
  async function initInstance() {
    console.log('sn-pixel init');
    await SnPixel.init();
  }

  useEffect(() => {
    initInstance();
  }, []);

  async function track<N>(
    event: N,
    data: [N]
  ): Promise<void> {
    SnPixel.pixel.track(event as any, data);
  }

  return [track];
}
