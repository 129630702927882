import { useEffect, useRef } from "react";

const usePortrait = (fn: Function, initial = true) => {
  const handler = useRef(fn);
  useEffect(() => {
    handler.current = fn;
  }, [fn]);
  useEffect(() => {
    const mediaQueryList = window.matchMedia("(orientation: portrait)");
    const handleOrientationChange = (mql: any) => {
      const callback = handler.current;
      if (mql.matches) {
        console.log("portrait");
        if (typeof callback === "function") {
          callback(true); // portrait
        }
      } else {
        console.log("landscape"); // landscape
        if (typeof callback === "function") {
          callback(false); // portrait
        }
      }
    };
    if (initial) {
      handleOrientationChange(mediaQueryList);
    }
    const hasEventListener =
      mediaQueryList && typeof mediaQueryList.addEventListener === "function";
    const hasOldEventListener =
      mediaQueryList && typeof mediaQueryList.addListener === "function";

    if (hasEventListener) {
      mediaQueryList.addEventListener("change", handleOrientationChange);
    } else {
      mediaQueryList.addListener(handleOrientationChange);
    }

    return () => {
      if (hasEventListener) {
        mediaQueryList.removeEventListener("change", handleOrientationChange);
      } else {
        mediaQueryList.removeListener(handleOrientationChange);
      }
    };
  }, [handler, initial]);
};

export default usePortrait;
